import React from "react";
import styled from "styled-components";
import kc from "../../assets/imgs/team/kc.png";
import jw from "../../assets/imgs/team/jw.png";
import wm from "../../assets/imgs/team/wm.png";
import Container from "../atoms/Container";

const people = [
  {
    name: "Will McLean",
    role: "Managing director",
    about:
      "Will has over 15 years experience as a designer and developer. He has led development on projects for clients such as Meta, ANZ, Ford, Google & Fairfax.",
    img: wm,
    linkedin: "https://www.linkedin.com/in/william-mclean-aba47b110/",
  },
  {
    name: "Kernow Craig",
    role: "Director",
    about:
      "With more than 20 years as a creative designer, Kernow has worked for multinational and boutique clients around the world.",
    img: kc,
    linkedin: "https://www.linkedin.com/in/kernow-craig-9082b278/",
  },
  {
    name: "Jeremy Walker",
    role: "Director",
    about:
      "Jeremy has global experience as a design director leading in-house and agency teams in Australia and the UK. His clients cover a range of industries and have included BBC, Experian, Westpac, IAG, Ford, City of Melbourne and Westfield.",
    img: jw,
    linkedin: "https://www.linkedin.com/in/jeremy-walker-7195621/",
  },
];

const Holder = styled.div`
  width: 100%;
  margin-bottom: 10rem;
  @media ( ${props => props.theme.breakpoints.sm} ) {
    width: 50%;
  }
`;

const Person = styled.div`
  margin-bottom: 2rem;
  .title {
    white-space: nowrap;
    ${(props) => props.theme.fluidType(3)};
    font-weight: normal;
    .role {
      ${(props) => props.theme.fluidType(1)};
    }
    .rarr {
      ${(props) => props.theme.fluidType(1)};
      margin: 0 0.2em
    }
    img {
      height: 0.8em;
      margin-right: 0.1em;
      display: inline-block;
    }
  }
`;

const NewTeam = () => {
  return (
    <Container>
      <Holder>
        {people.map((person, i) => (
          <Person key={i}>
            <h3 className="title"><img src={person.img} alt={person.name} /> <span className="name">{person.name}</span> <span className='rarr'>&rarr; </span> <span className="role">{person.role}</span></h3>
            <p>{person.about} <a
              className="fancy"
              target="_blank"
              rel="noreferrer"
              href={person.linkedin}
            >LinkedIn</a></p>
          </Person>
        ))}
      </Holder>
    </Container>
  );
};

export default NewTeam;

