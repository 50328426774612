import React from 'react';
import styled from 'styled-components';
import Container from "../atoms/Container";
import yellowStar from "../../assets/imgs/categories/yellow-star.png";
import redCurl from "../../assets/imgs/categories/red-curl.png";
import pinkBlock from "../../assets/imgs/categories/pink-block.png";
import greenBridge from "../../assets/imgs/categories/green-bridge.png";
import ProductTile from "../molecules/ProductTile";

const products = [
  {
    title: "Interactive Data Visualisation",
    background: "yellow",
    text: `We deliver interactive 3D tools, prototypes and data visualisations within the browser.`,
    img: yellowStar,
  },
  {
    title: "Virtual Reality",
    background: "red",
    text: `We deliver prototypes, research and experiments to help our clients explain, visualise and create in this evolving space.`,
    img: redCurl,
  },
  {
    title: "Rapid Prototyping",
    background: "pink",
    text: `We deliver working prototypes to help clients test and iterate digital products and services using real data.`,
    img: pinkBlock,
  },
  {
    title: "Visual Storytelling",
    background: "green",
    text: `We deliver visual narratives using animation, 3D, interactivity and multiple narratives running parallel all within the browser. 
    `,
    img: greenBridge,
  },
];

const Holder = styled.div`
  max-width: 70rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;

  @media (${(props) => props.theme.breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

function ProductTiles() {
  return (
    <Container>
      <Holder>
        {products.map((product, i) => (
          <ProductTile key={i} product={product} />
        ))}
      </Holder>
    </Container>
  )
}

export default ProductTiles;