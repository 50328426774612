import React from "react";
import styled from "styled-components";
import Seo from "../components/molecules/Seo";
import Intro from "../components/organisms/Intro";
import Clients from "../components/molecules/Clients";
import Footer from "../components/organisms/Footer";
import Team from "../components/organisms/Team";
import WorkList from "../components/organisms/WorkList";
import Heading from "../components/atoms/Heading";
import ProductTiles from "../components/organisms/ProductTiles";
import { graphql } from "gatsby";

const Holder = styled.div``;

const HomePage = ({ data }) => {
  return (
    <Holder>
      <Seo title="Home" />
      <Intro />
      <Heading title={"Products"} />
      <ProductTiles />
      <Clients />
      <Heading title={"Work"} />
      <WorkList projects={data.prismicHomepage.data.work} />
      <Heading title={"Team"} />
      <Team />
      <Footer />
    </Holder>
  );
};
export default HomePage;

export const pageQuery = graphql`
  query MyQuery {
    prismicHomepage {
      data {
        work {
          case_study {
            document {
              ... on PrismicCaseStudy {
                id
                uid
                data {
                  client_name
                  case_study_title {
                    text
                  }
                  live_link {
                    url
                  }
                  products {
                    product {
                      document {
                        ... on PrismicProduct {
                          uid
                        }
                      }
                    }
                  }
                  create_page
                  preview_embed {
                    video_id
                    height
                    width
                    title
                  }
                  preview_text {
                    richText
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

