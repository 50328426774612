import React from "react";
import styled from "styled-components";
import melbourne from "../../assets/imgs/clients/com-logo.png";
import fairfax from "../../assets/imgs/clients/fairfax-logo.png";
import westpac from "../../assets/imgs/clients/westpac-logo.png";
import meta from "../../assets/imgs/clients/meta-logo.png";
import google from "../../assets/imgs/clients/google-logo.png";
import anz from "../../assets/imgs/clients/anz-logo.png";
import ford from "../../assets/imgs/clients/ford-logo.png";
import Container from "../atoms/Container";
import Heading from "../atoms/Heading";

const Holder = styled.section`
  margin-bottom: 10rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin-bottom: 40vh;
  }
  p {
    ${(props) => props.theme.fluidType(2)};
    line-height: 1.1;
    @media ( ${props => props.theme.breakpoints.md} ) {
      ${(props) => props.theme.fluidType(3)};
    }
  }

  span {
    white-space: nowrap;
  }

  img {
    height: 0.8em;
    width: auto;
    margin: 0 0.1em;
    display: inline-block;
  }
;
`;

function Clients() {
  return (
    <>
      <Heading title={"Clients"} />
      <Container>
        <Holder>
          <p>
            We have delivered work for clients from around the world and across a
            broad range of industries. We are trusted by{" "}
            <span><img src={melbourne} alt="city of melbourne" /> City</span> of Melbourne,{" "}
            <span><img src={fairfax} alt="fairfax" /> Fairfax</span> Media,{" "}
            <span><img src={anz} alt="anz" /> ANZ,</span>{" "}
            <span><img src={meta} alt="meta" /> Meta,</span>{" "}
            <span><img src={ford} alt="ford" /> Ford,</span>{" "}
            <span><img src={google} alt="google" /> Google,</span> and{" "}
            <span><img src={westpac} alt="westpac" />Westpac.</span>
          </p>
        </Holder>
      </Container>
    </>
  );
}

export default Clients;

