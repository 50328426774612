import React from "react";
import styled from "styled-components";
import Container from "../atoms/Container";

const Holder = styled.div`
  max-width: 45rem;

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

function Intro() {
  return (
    <Holder>
      <Container>
        <p className="h1">
          A design & development studio creating extended reality and
          browser-based 3D products and services.
        </p>
        <p className="h4">
          We are a design and development studio that helps businesses take
          advantage of this exciting and unfamiliar landscape to create high
          impact, meaningful and beautiful digital work.
        </p>
      </Container>
    </Holder>
  );
}

export default Intro;

