import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: black;
  border-radius: 0.5rem;
  padding: 1rem;
  @media (${(props) => props.theme.breakpoints.sm}) {
    gap: 1rem;
  }
  @media (${(props) => props.theme.breakpoints.lg}) {
    gap: 1.2rem;
    padding: 1.2rem;
  }
  h4 {
    margin: 0;
    ${(props) => props.theme.fluidType(1)};
    @media (${(props) => props.theme.breakpoints.md}) {
      ${(props) => props.theme.fluidType(0)};
      min-height: 3rem;
    }
    @media (${(props) => props.theme.breakpoints.lg}) {
      ${(props) => props.theme.fluidType(1)};
    }
  }
  p {
    margin: 0;
    ${(props) => props.theme.fluidType(-1)};
    @media (${(props) => props.theme.breakpoints.lg}) {
      ${(props) => props.theme.fluidType(0)};
    }
  }
 

  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }

  &.bg-red {
    background-color: var(--red);
  }

  &.bg-yellow {
    background-color: var(--yellow);
  }

  &.bg-pink {
    background-color: var(--pink);
  }

  &.bg-green {
    background-color: var(--green);
  }
`;

const ImageHolder = styled.div`
  display: none;
  @media (${(props) => props.theme.breakpoints.md}) {
    display: block;
    margin: 0 2rem;
  }
  .inner {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  
  &.red {
    .inner {
      img {
        top: -5%;
        left: -5%;
        width: 110%;
        height: 110%;
      }
    }
  }

  &.pink {
    .inner {
      img {
        top: -10%;
        left: -10%;
        width: 120%;
        height: 120%;
      }
    }
  }

  &.green {
    .inner {
      img {
        top: -10%;
        left: -10%;
        width: 120%;
        height: 120%;
      }
    }
  }
`;

const ProductTile = ({product}) => {
  const {title, img, text, background} = product;
  return (
    <Holder className={`bg-${background}`}>
      <h4>{title}</h4>
      <ImageHolder className={background}>
        <div className="inner">
          <img src={img} alt="alt" />
        </div>
      </ImageHolder>
      <p>{text}</p>
    </Holder>
  );
};

ProductTile.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductTile;

